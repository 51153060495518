import userServices from '../../../services/user'
import orgServices from '../../../services/notisphere/organization'
import SitesModel from './SitesModel'
import { SITE_STATUS } from '../../../misc/commons'
import sitesService from '../../../services/provider/sites'

export default {
    components: {
        SitesModel
    },
    data() {
        return {
            total: 0,
            items: [],
            search: {
                organization_id: null,
                status: null,
                siteName: null,
            },
            searchQuery: {},
            organization_id: null,
            organizations: [],

            site_status: SITE_STATUS,
            sitesModalKey: 0,
        }
    },
    mounted() {
        if (this._isNotisphere) {
            this.fetchOrgMeta()
        }
    },
    computed: {
        header() {
            var arr = [
                {
                    label: 'Site Name',
                    name: 'siteName',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Site Address',
                    name: 'add1',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'GLN',
                    name: 'gln',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Number of Users',
                    name: 'userCount',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                },
                {
                    label: 'Status',
                    name: 'status',
                    sort: true,
                    row_text_alignment: 'text-left',
                    column_text_alignment: 'text-left',
                    column_classes: 'col-stt'
                },
                {
                    name: 'action',
                    row_text_alignment: 'text-right',
                    column_text_alignment: 'text-right',
                }
            ]

            if (this._isNotisphere) {
                return arr
            } else {
                return arr.filter(x => {
                    if (!['organization_name', 'organization_type'].includes(x.name)) {
                        return x
                    }
                })
            }
        },
    },
    methods: {
        onCompleted(type) {
            if (type == 'UPDATE') {
                this._showToast('Updated successfully!')
            }
            if (type == 'INSERT') {
                this._showToast('Added successfully!')
            }
            this.fetchData()
        },
        addMoreClick() {
            this.sitesModalKey += 1
            this.$nextTick(() => {
                this.$refs.sitesModel.forceRerenderInsert()
            })
        },
        updateSite(payload, e = null) {
            this.sitesModalKey += 1
            this.$nextTick(() => {
                this.$refs.sitesModel.forceRerenderUpdate(payload.site_id)
            })
            if (e) {
                e.stopPropagation()
            }
        },
        view(item, e = null){
            this.sitesModalKey += 1
            this.$nextTick(()=>{
                this.$refs.sitesModel.forceRerenderView(item.site_id, item.siteName)
            })
            if (e) {
                e.stopPropagation()
            }
        },
        fetchDelay() {
            this.$refs.table.fetchDelay()
        },
        fetchData() {
            sitesService.findSites({
                ...this.searchQuery,
                sortField: this.searchQuery.sort[0] ? this.searchQuery.sort[0].name : null,
                sortDirection: this.searchQuery.sort[0] ? this.searchQuery.sort[0].order : null,
                ...this.search
            }).then(resp => {
                if (!resp.error) {
                    this.items = resp.data.d.c
                    this.total = resp.data.d.t
                }
            })
        },
        fetchOrgMeta() {
            orgServices.findMeta().then(resp => {
                if (!resp.error) {
                    this.organizations = resp.data.d
                }
            })
        },
        ConfirmDeactivateSites(item,e) {
            sitesService.DeactiveSiteByReAssignSiteId(item.site_id, e.target.innerText).then(resp => {
                if (!resp.error) {
                    this.fetchData()
                    if (resp.data.d == 'INACTIVE') {
                        this._showToast('The site has been deactivated.')
                    }
                }
            })
        },
        activeDeactiveSite(item, e)
        {
            sitesService.activeDeactiveSite(item.site_id, e.target.innerText).then(resp => {
                if (!resp.error) {
                    this.fetchData()
                    if(resp.data.d == 'INACTIVE'){
                        this._showToast('The site has been deactivated.')
                    }
                    else{
                        this._showToast('The site has been activated.')
                    }
                }
            })
        },
        activeDeactive(item, e) {
            if(e.target.innerText=='Deactivate')
            {
                if(item.userCount>0)
                {
                    this._showConfirm('This site currently has users assigned to it. If you continue with the deactivation process, all users assigned to this site will be reassigned to the Corporate site. If you would like to assign different sites to those users, click Cancel and assign a different site to each user.').then(confirm => {
                        if (confirm) {
                            if(item.locationSiteCount>0)
                            {
                                this._showConfirm('This site currently has locations assigned to it. If you continue with the deactivation process, all locations assigned to this site will be reassigned to the Corporate site. If you would like to assign different sites to those locations, click Cancel and assign a different site to each location.').then(confirm => {
                                    if (confirm) {
                                        this.ConfirmDeactivateSites(item,e)
                                    }
                                })
                            }
                            else{
                                this.ConfirmDeactivateSites(item,e)
                            }
                        }
                    })
                }
                if(item.userCount==0 && item.locationSiteCount>0)
                {
                    this._showConfirm('This site currently has locations assigned to it. If you continue with the deactivation process, all locations assigned to this site will be reassigned to the Corporate site. If you would like to assign different sites to those locations, click Cancel and assign a different site to each location.').then(confirm => {
                        if (confirm) {
                            this.ConfirmDeactivateSites(item,e)
                        }
                    })
                }
                if(item.userCount==0 && item.locationSiteCount==0)
                {
                    this.activeDeactiveSite(item,e)
                }
            }
            else{
                this.activeDeactiveSite(item,e)
            }

            if (e) {
                e.stopPropagation()
            }
        },
    }
}